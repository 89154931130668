<template>
  <div>
    <table class="table" aria-describedby="table" v-if="table.data">
      <caption v-if="table.label">{{table.label}}</caption>
      <tr class="text-success" v-if="table.headers">
        <th>
        </th>
        <th v-for="(header, index) in table.headers" :key="index">
          {{header}}
        </th>
      </tr>
      <template v-else>
        <tr class="text-success" v-if="isShow && table.data.length">
          <th>
            <b-form-checkbox
                    v-if="isCheckbox"
                    :checked="getAllChecked()"
                    @change="setAllChecked(requestParam, $event)"
            />
          </th>
          <th class="header-cell" @click="sortTable(column)" v-for="(column, index)  in table.columns" :key="index">
            {{column.header}}
            <div class="sort-arrow"></div>
          </th>
        </tr>
      </template>
      <template v-if="table.headers">
        <tr v-for="(item, index) in table.data"
            :key="index" :class="{ 'chosenAccount' : selectAccounts.findIndex(item => item === item['account']) > -1}">
          <td>
            <b-form-checkbox
                    :id="'account_' + index"
                    :value="item['account']"
                    v-if="isCheckbox"
                    v-model="data"
                    name="account"
                    @change="setRequestParam()"
                    :disabled="!multiple ? selectAccounts.length === 1: false"
            />
            <b-form-radio
                    :id="'account_' + index"
                    :value="item['account']"
                    v-if="!isCheckbox"
                    v-model="data"
                    @change="setRequestParam()"
                    name="account"/>
          </td>
          <td v-for="(header, ind) in table.headers" :key="ind">
            {{ item[ind] }}
          </td>
        </tr>
      </template>
      <template v-else>
        <template v-if="isShow && table.data.length">
          <tr v-for="(row, index) in table.data"
              :key="index"
              :class="{ 'chosenAccount' : isCheckbox ? selectAccounts.findIndex(item => item === row.contract_ref) > -1 : selectAccounts === row.contract_ref}">
            <td>
              <b-form-checkbox
                      :id="'account_' + index"
                      :value="row[requestParam]"
                      v-if="isCheckbox"
                      v-model="data"
                      name="account"
                      @change="setRequestParam()"
              />
              <b-form-radio
                      :id="'account_' + index"
                      :value="row[requestParam]"
                      v-if="!isCheckbox"
                      v-model="data"
                      @change="setRequestParam()"
                      name="account"/>
            </td>
            <td v-for="(column, index) in table.columns" :key="index">{{ row[column.field] }} </td>
          </tr>
        </template>
        <template v-else>
          <span class="table-no-data">{{table.no_data_closed || table.no_data}}</span>
        </template>
      </template>
    </table>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "CustomTable",
  components: {},
  props: {
    display: {
      type: Boolean,
      required: true,
      default: true,
    },
    showTableData: {
      type: Boolean,
      required: true,
      default: true,
    },
    isCheckbox: {
      type: Boolean,
      required: true,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    table: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    requestData: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    requestParam: {
      type: String,
      required: false,
    },
    selectAccounts: {
      required: true,
      default() {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: [],
      isShow: this.showTableData
    }
  },
  methods: {
    sortTable(column) {
      this.isShow = false;
      const field = column?.field
      const isSameParam = field === this.paramForSortTable;
      this.isReverseTableData = isSameParam ? !this.isReverseTableData : false;
      const sortDirection = this.isReverseTableData && isSameParam;
      if (field) {
        this.$nextTick(() => {
          this.table.data = this.table.data.sort((a, b) => {
            const firstValue = moment(a[field], 'DD.MM.YYYY').isValid() ? moment(a[field], 'DD.MM.YYYY') : a[field];
            const secondValue = moment(b[field], 'DD.MM.YYYY').isValid() ? moment(b[field], 'DD.MM.YYYY') : b[field];
            if (firstValue < secondValue) {
              return sortDirection ? 1 : -1;
            }
            if (firstValue > secondValue) {
              return sortDirection ? -1 : 1;
            }
            return 0;
          })
          this.isShow = true;
          this.paramForSortTable = field;
        });
      }
    },
    setRequestParam() {
      this.$emit("change", this.data);
    },
    getAllChecked() {
      return this.data.length === this.table.data.length;
    },
    setAllChecked(param, event) {
      this.data = event ? this.table.data.map(el => el[param]) : [];
      this.$emit("change", this.data);
    }
  },
  watch: {
    selectAccounts(newValue) {
      if (!newValue.length) {
        this.data = newValue;
      }
    }
  }
}
</script>

<style lang="scss">
   .nav-tabs {
     width: 50%;
   }
  .table {
    padding-top: 0;
  }
  .custom-control-input {
    z-index: 10;
  }
  .table-no-data {
    margin: 20px 0 0 20px;
    font-weight: bold;
    color: #28a745;
    display: block;
  }
</style>
